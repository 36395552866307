export function getNumberOfLinesOfText(element) {
  const style = window.getComputedStyle(element)
  const lineHeight = parseFloat(style.lineHeight)
  const height = element.offsetHeight
  return Math.ceil(height / lineHeight)
}

export function htmlToElement(html) {
  const template = document.createElement('template')
  template.innerHTML = html.trim()
  return template.content.firstElementChild
}
